
import parameters from './parameters.json';
switch ((new URL(window.location.href)).pathname) {
    case '/en': {
        localStorage.setItem('locale', 'en-US');
        break;
    }
    case '/fr': {
        localStorage.setItem('locale', 'fr-FR');
        break;
    }
    case '/reset': {
        localStorage.removeItem('locale');
        break;
    }
}
window.showVersion = () => console.log(version);
const {
    version,
} = parameters;
Promise.all([
    import('react'),
    import('react-dom/client'),
    import('./App')
]).then(([
    { default: React },
    { createRoot },
    { default: App }
]) => {
    createRoot(
        document.getElementById('root')
    ).render(
        React.createElement(App)
    );
});
